<template>
  <v-card class="pa-8">
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <h3>แก้ไขหมวดหมู่</h3>
      <v-row>
        <v-col cols="2"><p class="pt-2">ชื่อหมวดหมู่</p></v-col>
        <v-col cols="6">
          <v-text-field
            v-model="categoryname"
            label="ชื่อหมวดหมู่"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2"><p class="pt-2">รายละเอียด</p></v-col>
        <v-col cols="6">
          <v-text-field
            v-model="categoryDescription"
            label="รายละเอียด"
            outlined
            dense
            :rules="rules.required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-btn @click="cancel()">กลับ</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="submit()">บันทึก</v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      categoryname: "",
      categoryDescription: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  created() {
    var Catedata = JSON.parse(Decode.decode(localStorage.getItem("Catedata")));
    console.log("Catedata", Catedata);
    this.id = Catedata.id;
    this.categoryname = Catedata.category;
    this.categoryDescription = Catedata.description;
  },
  methods: {
    cancel() {
      this.$router.push("ManageCategory");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        const data = {
          id: this.id,
          category: this.categoryname,
          description: this.categoryDescription,
        };
        var user = JSON.parse(Decode.decode(localStorage.getItem("user")));
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/categories/` + this.id,
          data,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างหมวดหมู่สินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageCategory");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างหมวดหมู่สินค้าไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>